
import {Component, Vue, Watch} from "vue-property-decorator";
import GelirGiderInfo from "@/components/infos/GelirGiderInfo.vue";
import GiderInfo from "@/components/infos/GiderInfo.vue";
import GelirInfo from "@/components/infos/GelirInfo.vue";
import Dates from "@/components/inputs/Dates.vue";
import {GelirGiderEntity} from "@/entity/GelirGiderEntity";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import OzelAralikDatePicker from "@/components/infos/raporlama-ofispro/helper-comps/OzelAralikDatePicker.vue";
import SayfaAciklama from "@/components/SayfaAciklama.vue";

@Component({
  components: {SayfaAciklama, OzelAralikDatePicker, ParaBirimiPicker, Dates, GelirInfo, GiderInfo, GelirGiderInfo}
})
export default class KasaInfo extends Vue {
  isGelirveGider: boolean = localStorage.getItem('kasa-tablo-tercih') ? JSON.parse(localStorage.getItem('kasa-tablo-tercih')??'') : true;  tarihAraligi = {
    baslangicTarihi: '',
    bitisTarihi: ''
  }
  paraBirimi=null;
  oldFilter={
    baslangicTarihi: '',
    bitisTarihi: '',
    paraBirimi: null
  };

  selectedItem = null
  tarihItems = ["Bu Yıl", "Bu Ay", "Bu Hafta"]
  items: Array<GelirGiderEntity> = [];
  gelirItems: Array<GelirGiderEntity> = [];
  giderItems: Array<GelirGiderEntity> = [];

  @Watch('isGelirveGider')
    control(){
  }


  selectItem(item:any) {
    if (item === this.selectedItem) {
      return;
    }
    this.selectedItem = item;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() 0-11 aralığında değer döndürür, bu yüzden 1 ekliyoruz.

    if(this.selectedItem === "Bu Yıl"){
      this.tarihAraligi.baslangicTarihi = `${currentYear}-01-01`;
      this.tarihAraligi.bitisTarihi = `${currentYear}-12-31`;
    } else if (this.selectedItem === "Bu Ay") {
      const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0);

      this.tarihAraligi.baslangicTarihi = `${currentYear}-${String(currentMonth).padStart(2, '0')}-01`;
      this.tarihAraligi.bitisTarihi = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${lastDayOfMonth.getDate()}`;
    }else if (this.selectedItem === "Bu Hafta") {
      const firstDayOfWeek = new Date(currentDate);
      const lastDayOfWeek = new Date(currentDate);

      // Haftanın ilk gününü belirlemek için
      const dayOfWeek = currentDate.getDay();
      const diffToFirstDay = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
      firstDayOfWeek.setDate(currentDate.getDate() + diffToFirstDay);

      // Haftanın son gününü belirlemek için
      lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

      this.tarihAraligi.baslangicTarihi = firstDayOfWeek.toISOString().substr(0, 10);
      this.tarihAraligi.bitisTarihi = lastDayOfWeek.toISOString().substr(0, 10);
    }


  }
  get gelirTutarlari() {
    let gelirler: Array<GelirGiderEntity> = [];
    let tutarlar: Array<GelirGiderEntity> = [];
    if (this.isGelirveGider) {
      gelirler = this.items.filter((item: GelirGiderEntity) => item.gelir_mi == true);
    } else {
      gelirler = this.gelirItems;
    }
    gelirler.forEach((gelir: GelirGiderEntity) => {
      if (gelir.hesaba_eklensin_mi) {
        let tutar=tutarlar.find(tutar => tutar.para_birimi_id == gelir.para_birimi_id)
        if (!tutar)
          tutarlar.push({...gelir});
        else
          tutar.mutlak_tutar += gelir.mutlak_tutar;
      }
    });
    return tutarlar;
  }

  get giderTutarlari() {
    let giderler: Array<GelirGiderEntity> = [];
    let tutarlar: Array<GelirGiderEntity> = [];
    if (this.isGelirveGider) {
      giderler = this.items.filter((item: GelirGiderEntity) => item.gelir_mi == false);
    } else {
      giderler = this.giderItems;
    }
    giderler.forEach((gider: GelirGiderEntity) => {
      if (gider.hesaba_eklensin_mi) {
        let tutar=tutarlar.find(tutar => tutar.para_birimi_id == gider.para_birimi_id)
        if (!tutar)
          tutarlar.push({...gider});
        else
          tutar.mutlak_tutar += gider.mutlak_tutar;
      }
    });
    return tutarlar;
  }

  get netTutarlar() {
    let gelirler: Array<GelirGiderEntity> = [...this.gelirTutarlari];
    let giderler: Array<GelirGiderEntity> = [...this.giderTutarlari];
    let netTutarlar: Array<GelirGiderEntity> = [];
    gelirler.forEach((gelir: GelirGiderEntity) => {
      let gelirItem = {...gelir}
      let giderItem = giderler.find(gider => gider.para_birimi_id == gelir.para_birimi_id);
      if (giderItem)
        gelirItem.tutar = Number(gelirItem.mutlak_tutar) - Number(giderItem.mutlak_tutar);
      else
        gelirItem.tutar = Number(gelirItem.mutlak_tutar);
      netTutarlar.push(gelirItem);
    });
    giderler.forEach((gider: GelirGiderEntity) => {
      let gelirItem = gelirler.find(gelir => gelir.para_birimi_id == gider.para_birimi_id);
      if (!gelirItem) {
        let giderItem = {...gider}
        giderItem.tutar = giderItem.mutlak_tutar * (-1);
        netTutarlar.push(giderItem)
      }
    });
    return netTutarlar;
  }

  mounted() {
    this.isGelirveGider=false
    this.tarihAraligi.bitisTarihi = new Date().toISOString().substr(0, 10);
    this.tarihAraligi.baslangicTarihi = new Date().toISOString().substr(0, 10).slice(0, -2) + '01';
    if (localStorage.getItem('kasa-tablo-tercih') == null)
      localStorage.setItem('kasa-tablo-tercih', JSON.stringify(this.isGelirveGider));
    this.load();
  }

  load(forceLoad:boolean = false) {
    localStorage.setItem('kasa-tablo-tercih', JSON.stringify(this.isGelirveGider));
    if (this.tarihAraligi.baslangicTarihi != this.oldFilter.baslangicTarihi ||
        this.tarihAraligi.bitisTarihi != this.oldFilter.bitisTarihi ||
        this.paraBirimi != this.oldFilter.paraBirimi ||
        forceLoad) {
      if (this.isGelirveGider) {
        this.$http.get('/api/v1/kasa-hareketi/filter', {
          params: {
            baslangicTarihi: this.tarihAraligi.baslangicTarihi,
            bitisTarihi: this.tarihAraligi.bitisTarihi,
            paraBirimi: this.paraBirimi
          }
        }).then((response) => this.items = response.data);
      } else {
        this.$http.get('/api/v1/kasa-hareketi/filter', {
          params: {
            baslangicTarihi: this.tarihAraligi.baslangicTarihi,
            bitisTarihi: this.tarihAraligi.bitisTarihi,
            paraBirimi: this.paraBirimi,
            gelir: 1
          }
        }).then((response) => this.gelirItems = response.data);
        this.$http.get('/api/v1/kasa-hareketi/filter', {
          params: {
            baslangicTarihi: this.tarihAraligi.baslangicTarihi,
            bitisTarihi: this.tarihAraligi.bitisTarihi,
            paraBirimi: this.paraBirimi,
            gelir: 0
          }
        }).then((response) => this.giderItems = response.data);
      }
      this.oldFilter.baslangicTarihi = this.tarihAraligi.baslangicTarihi;
      this.oldFilter.bitisTarihi = this.tarihAraligi.bitisTarihi;
      this.oldFilter.paraBirimi = this.paraBirimi;
    }
  }
}
